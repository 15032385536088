import React from "react";

function NavBar() {
  return (
    <nav class="bg-white border-gray-200 ">
      <div class="max-w-screen-xl flex my-2 mx-7 flex-wrap items-center justify-between">
        <a
          href="https://flowbite.com/"
          class="flex items-center space-x-3 rtl:space-x-reverse"
        >
          <img
            src="assets/svg/logo.svg"
            class="h-8"
            alt="Flowbite Logo"
          />
          <span class="text-2xl font-semibold whitespace-nowrap dark:text-white">
          <h1 className="text-[#474747] text-lg font-normal"> Workflow: Process - Rotation Travel Request - Travel
                    Specialist RTR-2024013904</h1>

          </span>
        </a>
      
      </div>
    </nav>
  );
}

export default NavBar;
