import React from "react";
import Modal from "../../components/common/modal";
import { IoClose } from "react-icons/io5";
import { toast } from "react-toastify";

const ConfirmationModal = ({ onClose, onSuccess }) => {
    const agents = [
        "All",
        "Akbar Travels",
        " Al Ostora Travel Company.",
        " Alshamel Travel",
        " Akbar Travels",
        "Captain Tours and Travels Company",
        " Akbar Travels",
        " Alshamel Travel",
        " Captain Tours and Travels Company",
        " Akbar Travels ",
        " Golden Gate",
        "Akbar Travels",
    ];

    return (
        <Modal.Body className=" ">
            <div className="flex items-center justify-between px-[25px] text-center pt-[25px] rounded-t">
                <h3 className=" text-[#013E4F] mb-[24px] ">
                    Are you sure you want to send
                    <br /> the request?
                </h3>
            </div>

            <hr />

            <Modal.Footer>
                <button
                    onClick={onClose}
                    className="text-[#FFFFFF] bg-[#BCBCBC] flex items-center border border-[#D9D9D9] rounded-[3px] px-[24px] h-[40px] gap-1"
                >
                    <IoClose className="h-[22px] w-[22px]" />
                    Cancel
                </button>
                <button
                    onClick={() => {
                        onSuccess();
                        toast.success("Successfully sent ticket request");
                    }}
                    className="text-[#FFFFFF] bg-[#25A9F3] flex items-center border border-[#D9D9D9] rounded-[3px] px-[40px] h-[40px]"
                >
                    Yes
                </button>
            </Modal.Footer>
        </Modal.Body>
    );
};

export default ConfirmationModal;
