import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import NavBar from "./components/navbar";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";

function App() {
  return (
    <div className="container-fluid flex justify-center items-center flex-col ">
      <div className="container mx-auto">
        <NavBar />
        <Outlet />
      </div>
      <ToastContainer
        closeOnClick
        style={{ width: "auto", minWidth: "340px", maxWidth: "450px" }}
        position={"top-right"}
        bodyStyle={{ color: "#756f86" }}
      />
    </div>
  );
}

export default App;
