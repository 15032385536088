import React from "react";
import Modal from "../../components/common/modal";
import { IoClose } from "react-icons/io5";
import { toast } from "react-toastify";
import { TfiClose } from "react-icons/tfi";

const RejectionModal = ({ onClose, onSuccess, openRejectionModal }) => {
    if (!openRejectionModal) {
        return null;
    }
    return (
        <Modal.Body className="">
            <div className="px-[25px]">
                <div className="flex items-center justify-between  text-center pt-[25px] rounded-t min-w-[500px]">
                    <h3 className=" text-[#013E4F]  text-medium text-xl">
                        Add Remarks
                    </h3>
                    <TfiClose
                        className="w-[22px] h-[22px] text-[#B7B9BB]"
                        onClick={onClose}
                    />
                </div>
                <p className="my-[8px]">
                    Please add your rejection notes here..
                </p>

                <textarea
                    className="resize-none border rounded-md w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline min-h-[86px]"
                    name="reason"
                    placeholder="Text here"
                    rows={7}
                />
            </div>
            <Modal.Footer>
                <button
                    onClick={onClose}
                    className="text-[#FFFFFF] bg-[#BCBCBC] flex items-center border border-[#D9D9D9] rounded-[3px] px-[24px] h-[40px] gap-1"
                >
                    <IoClose className="h-[22px] w-[22px]" />
                    Cancel
                </button>
                <button
                    onClick={() => {
                        onSuccess();
                    }}
                    className="text-[#FFFFFF] bg-[#25A9F3] flex items-center border border-[#D9D9D9] rounded-[3px] px-[40px] h-[40px]"
                >
                    Yes
                </button>
            </Modal.Footer>
        </Modal.Body>
    );
};

export default RejectionModal;
