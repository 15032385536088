import React, { useState } from "react";
// import eye from "assets/svg/eye.svg";
import { FaAward } from "react-icons/fa";
import { IoMdArrowDown } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { toast } from "react-toastify";
import { ticketReqUsers, tickets } from "../../data";
import RejectionModal from "./rejectionModal";
import { ticketData } from "../../data";
import { ListItem } from "./listItem";

const Approval = () => {
  const [openRejectionModal, setOpenRejectionModal] = useState(false);

  return (
    <>
      <RejectionModal
        onSuccess={() => {
          toast.success("The travel plan has been rejected successfully");
          setOpenRejectionModal(false);
        }}
        onClose={() => setOpenRejectionModal(false)}
        openRejectionModal={openRejectionModal}
      />

      <div className="mx-7">
        <div className="flex justify-between">
          <div className="my-2  flex gap-7 font-medium text-sm">
            <span className="text-[#1BABF8] border-b-[3px] border-[#1BABF8]">
              Workflow From
            </span>
            <span>Workflow Chart</span>
            <span>Process status</span>
            <span>Related resources </span>
          </div>
        </div>

        <div className="my-4">
          <p className="text-2xl font-medium">Employee Travel Details</p>
        </div>
        {ticketReqUsers.slice(0, 1).map((it) => (
          <div className="w-full max-h-[250px] border shadow-[0px_2px_10px_0px_#0000002E] rounded-xl flex p-6 gap-28 border-[#F5F4F4]">
            <div className="w-[30%] flex gap-8">
              <div className="flex flex-col gap-2 ms-4">
                <p className="text-sm text-[#A2ACAF] mb-2">Travellers</p>
                <div className="flex gap-3">
                  <img
                    src={it?.image}
                    alt=""
                    className="w-6 h-6 rounded-full"
                  />
                  <p>{it?.name}</p>
                  <img src={"assets/svg/eye.svg"} alt="" />
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-8 w-full ">
              {it?.tickets?.map((it, ind) => (
                <>
                  {ind > 0 && (
                    <div className="flex items-center gap-6">
                      <p className="text-base font-normal text-[#174A28] italic">
                        Return
                      </p>
                      <div className="w-full h-[1px] bg-[#D8DCD2]"></div>
                    </div>
                  )}
                  <div className="flex justify-between pe-10">
                    <div className="">
                      <p className="text-sm text-[#A2ACAF] mb-2">From</p>
                      <p className="text-base font-medium ">Kuwait</p>
                    </div>
                    <div>
                      <p className="text-sm text-[#A2ACAF] mb-2">To</p>
                      <p className="text-base font-medium">Doha</p>
                    </div>
                    <div>
                      <p className="text-sm text-[#A2ACAF] mb-2">
                        Date and Time
                      </p>
                      <p className="text-base font-medium">2024-02-14, 06:00</p>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
        ))}

        <div className="my-4">
          <p className="text-2xl font-medium">Selected Plan</p>
        </div>

        <div>
          <table className="w-full text-sm text-left">
            <thead className=" bg-[#1BABF8]   font-medium text-white rounded-md">
              <tr className="">
                <td></td>
                <td>AIRLINE</td>
                <td>DEPARTURE</td>
                <td>DURATION</td>
                <td>ARRIVAL</td>
                <td>AGENT</td>
                <td className="flex items-center">
                  PRICE <IoMdArrowDown className="h-[16px] w-[16px]" />
                </td>
              </tr>
            </thead>
            <td className="p-1 m-0"></td>

            <tbody className="rounded-md w-full">
              {tickets.slice(1, 2).map((item, i) => (
                <ListItem index={i} data={item} />
              ))}
            </tbody>
          </table>
          <div className="flex justify-end gap-2 mt-2">
            <button
              onClick={() => setOpenRejectionModal(true)}
              className="text-[#FFFFFF] bg-[#BCBCBC] flex items-center border border-[#D9D9D9] rounded-[3px] px-[24px] h-[40px] gap-1"
            >
              <IoClose className="h-[22px] w-[22px]" />
              Reject
            </button>
            <button
              className="text-[#FFFFFF] bg-[#25A9F3] flex items-center border border-[#D9D9D9] rounded-[3px] px-[40px] h-[40px]"
              // onClick={() => setOpenConfirmation(true)}
              onClick={() => {
                toast.success("The travel plan has been approved successfully");
              }}
            >
              Approve
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Approval;

{
  /* <tr>
                                <td>
                                    <input
                                        type="checkbox"
                                        id="checkbox1"
                                        className="peer relative mt-8 h-6 w-6 shrink-0 appearance-none rounded-sm border after:absolute after:left-0 after:top-0 after:h-full after:w-full after:bg-[url('data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjZmZmZmZmIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgdmVyc2lvbj0iMS4xIiB4PSIwcHgiIHk9IjBweCI+PHRpdGxlPmljb25fYnlfUG9zaGx5YWtvdjEwPC90aXRsZT48ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz48ZyBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48ZyBmaWxsPSIjZmZmZmZmIj48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyNi4wMDAwMDAsIDI2LjAwMDAwMCkiPjxwYXRoIGQ9Ik0xNy45OTk5ODc4LDMyLjQgTDEwLjk5OTk4NzgsMjUuNCBDMTAuMjI2Nzg5MSwyNC42MjY4MDE0IDguOTczMTg2NDQsMjQuNjI2ODAxNCA4LjE5OTk4Nzc5LDI1LjQgTDguMTk5OTg3NzksMjUuNCBDNy40MjY3ODkxNCwyNi4xNzMxOTg2IDcuNDI2Nzg5MTQsMjcuNDI2ODAxNCA4LjE5OTk4Nzc5LDI4LjIgTDE2LjU4NTc3NDIsMzYuNTg1Nzg2NCBDMTcuMzY2ODIyOCwzNy4zNjY4MzUgMTguNjMzMTUyOCwzNy4zNjY4MzUgMTkuNDE0MjAxNCwzNi41ODU3ODY0IEw0MC41OTk5ODc4LDE1LjQgQzQxLjM3MzE4NjQsMTQuNjI2ODAxNCA0MS4zNzMxODY0LDEzLjM3MzE5ODYgNDAuNTk5OTg3OCwxMi42IEw0MC41OTk5ODc4LDEyLjYgQzM5LjgyNjc4OTEsMTEuODI2ODAxNCAzOC41NzMxODY0LDExLjgyNjgwMTQgMzcuNzk5OTg3OCwxMi42IEwxNy45OTk5ODc4LDMyLjQgWiI+PC9wYXRoPjwvZz48L2c+PC9nPjwvc3ZnPg==')] after:bg-[length:40px] after:bg-center after:bg-no-repeat after:content-[''] checked:bg-[#25A9F3] hover:ring hover:ring-gray-300 focus:outline-none "
                                    />
                                </td>
                                <td className="space-y-4 p-0">
                                    <div className=" bg-[#25A9F3] text-[#FFFFFF]  text-[15px] font-medium px-2 py-1.5 rounded-b-[10px] w-fit flex items-center gap-1">
                                        <FaAward className="w-[19px] h-[19px]" />{" "}
                                        Best Value
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <img
                                            src="assets/img/airways.png"
                                            className="w-[45px] h-[15px]"
                                        />
                                        <div className="font-medium ">
                                            <h6 className="text-lg">
                                                Jazeera Airways
                                            </h6>
                                            <p className="text-gray-500">
                                                J9-117
                                            </p>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <p className="text-2xl font-medium">
                                        15:35
                                    </p>
                                    <p className="text-sm text-[#A2ACAF] mb-2">
                                        Kuwait
                                    </p>
                                </td>

                                <td className="text-sm ">
                                    <span className="ms-8">
                                        <strong>1</strong> Hr.{" "}
                                        <strong>25</strong> Min.
                                    </span>
                                    <img
                                        src="https://media.giphy.com/media/dwEwt4MHTWxoC6gOlp/giphy.gif"
                                        alt=""
                                        className="w-[200px] h-[40px] -ml-6 -mt-3"
                                    />
                                </td>
                                <td>
                                    <p className="text-2xl font-medium">
                                        17:00
                                    </p>
                                    <p className="text-sm text-[#A2ACAF] mb-2">
                                        Doha
                                    </p>
                                </td>
                                <td>
                                    <img
                                        src="assets/img/Golden-Gate-Travels-Logo.png"
                                        className="w-[180px] h-[50px]"
                                    />
                                </td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td>
                                    <p className="text-2xl font-medium">
                                        18:00
                                    </p>
                                    <p className="text-sm text-[#A2ACAF] mb-2">
                                        Doha
                                    </p>
                                </td>
                                <td>
                                    <span className="ms-8">
                                        <strong>1</strong> Hr.{" "}
                                        <strong>15</strong> Min.
                                    </span>
                                    <img
                                        src="https://media.giphy.com/media/dwEwt4MHTWxoC6gOlp/giphy.gif"
                                        alt=""
                                        className="w-[200px] h-[40px] -ml-6 -mt-3"
                                    />
                                </td>
                                <td>
                                    <p className="text-2xl font-medium">
                                        19:15
                                    </p>
                                    <p className="text-sm text-[#A2ACAF] mb-2">
                                        Kuwait
                                    </p>
                                </td>
                                <td colSpan={2}>
                                    <p className="text-lg text-end">
                                        KWD
                                        <strong className="text-2xl ms-2">
                                            18575
                                        </strong>
                                    </p>
                                     
                                </td>
                            </tr> */
}
