import { useState } from "react";
import { FaAward } from "react-icons/fa";
import { MdAirlineSeatReclineExtra } from "react-icons/md";
import qatarAirwaysPic from "../../assets/img/home-pic-5.png";
import goldenGate from "../../assets/img/Golden-Gate-Travels-Logo.png";
import { agents } from "../../data";

export function ListItem({ data, index }) {
  return (
    <>
      <div className="mb-4 bg-transparent" />
      <tr
        className="bg-white hover:shadow-[0px_2px_10px_0px_#0000002E] mb-[2px] pt-4"
        style={{
          boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
        }}
      >
        <td>
          <input
            type="checkbox"
            id="checkbox1"
            className="peer relative mt-8 h-6 w-6 shrink-0 appearance-none rounded-sm border after:absolute after:left-0 after:top-0 after:h-full after:w-full after:bg-[url('data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjZmZmZmZmIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgdmVyc2lvbj0iMS4xIiB4PSIwcHgiIHk9IjBweCI+PHRpdGxlPmljb25fYnlfUG9zaGx5YWtvdjEwPC90aXRsZT48ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz48ZyBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48ZyBmaWxsPSIjZmZmZmZmIj48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyNi4wMDAwMDAsIDI2LjAwMDAwMCkiPjxwYXRoIGQ9Ik0xNy45OTk5ODc4LDMyLjQgTDEwLjk5OTk4NzgsMjUuNCBDMTAuMjI2Nzg5MSwyNC42MjY4MDE0IDguOTczMTg2NDQsMjQuNjI2ODAxNCA4LjE5OTk4Nzc5LDI1LjQgTDguMTk5OTg3NzksMjUuNCBDNy40MjY3ODkxNCwyNi4xNzMxOTg2IDcuNDI2Nzg5MTQsMjcuNDI2ODAxNCA4LjE5OTk4Nzc5LDI4LjIgTDE2LjU4NTc3NDIsMzYuNTg1Nzg2NCBDMTcuMzY2ODIyOCwzNy4zNjY4MzUgMTguNjMzMTUyOCwzNy4zNjY4MzUgMTkuNDE0MjAxNCwzNi41ODU3ODY0IEw0MC41OTk5ODc4LDE1LjQgQzQxLjM3MzE4NjQsMTQuNjI2ODAxNCA0MS4zNzMxODY0LDEzLjM3MzE5ODYgNDAuNTk5OTg3OCwxMi42IEw0MC41OTk5ODc4LDEyLjYgQzM5LjgyNjc4OTEsMTEuODI2ODAxNCAzOC41NzMxODY0LDExLjgyNjgwMTQgMzcuNzk5OTg3OCwxMi42IEwxNy45OTk5ODc4LDMyLjQgWiI+PC9wYXRoPjwvZz48L2c+PC9nPjwvc3ZnPg==')] after:bg-[length:40px] after:bg-center after:bg-no-repeat after:content-[''] checked:bg-[#25A9F3] hover:ring hover:ring-gray-300 focus:outline-none "
          />
        </td>
        <td className="relative">
          {data?.isBest && (
            <div className="w-[96px] h-[26px] bg-[#25A9F3] text-[#FFFFFF] absolute top-0  text-[12px] font-medium rounded-b-[10px] flex justify-center items-center gap-1">
              <FaAward className="w-[19px] h-[19px]" /> Best Value
            </div>
          )}
          <div className="flex  gap-2">
            <img
              className="w-[30px] h-[30px] rounded-full border"
              src={data?.airlineLogo}
              alt=""
              srcset=""
            />
            <div className="font-medium ">
              <h6 className="">{data?.airline}</h6>
              <p className="text-gray-500 text-xs">{data?.airlineCode}</p>
            </div>
          </div>
        </td>

        <td colSpan={3} className="">
          {data?.times?.map((it, ind) => (
            <tr className="w-full space-x-16 flex">
              <td>
                <div className="mr-[2px] pt-2"></div>
                <div>
                  <p className="text-2xl font-medium">{it?.from}</p>

                  <p className="text-sm text-[#A2ACAF] mb-2">{data?.from}</p>
                </div>
              </td>

              <td className="text-center">
                <span className="text-[12px]">
                  <strong>{it?.duration?.hr}</strong> Hr.{" "}
                  <strong>{it?.duration?.min}</strong> Min.
                </span>
                <img
                  src="https://media.giphy.com/media/dwEwt4MHTWxoC6gOlp/giphy.gif"
                  alt=""
                  className="w-[150px] h-[35px] object-cover"
                />
              </td>

              <td>
                <p className="text-2xl font-medium">{it?.to}</p>
                <p className="text-sm text-[#A2ACAF] mb-2">{data?.to}</p>
              </td>
            </tr>
          ))}
        </td>
        <td>
          <img src={data?.agent?.image} className="w-[180px] h-[50px]" />
        </td>
        <td>
          <p className="text-lg text-start">
            {`${data?.currency}`}
            <strong className="text-2xl ms-2">{data?.maxPrice}</strong>
          </p>
        </td>
      </tr>
    </>
  );
}
