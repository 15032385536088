import React, { useState } from "react";
import { RiSearch2Line } from "react-icons/ri";
import { IoIosArrowDown } from "react-icons/io";
import { FiCalendar } from "react-icons/fi";
import { MdChevronLeft } from "react-icons/md";
import { MdKeyboardArrowRight } from "react-icons/md";
import SelectModal from "./selectModal";
import { ticketReqUsers } from "../../data";

function Home() {
  const [openAgentModal, setOpenAgentModal] = useState(false);

  return (
    <div className="flex flex-col justify-center ">
      <div className="flex justify-between">
        <div className="my-2 mx-7 flex gap-7 font-medium text-sm">
          <span className="text-[#1BABF8] border-b-[3px] border-[#1BABF8]">
            Workflow From
          </span>
          <span>Workflow Chart</span>
          <span>Process status</span>
          <span>Related resources </span>
        </div>
        <div className="*:bg-[#1BABF8] *:px-7 *:py-2 text-white space-x-2.5 text-[15px] *:rounded-[3px]">
          <button>Approve</button>
          <button>Reject</button>
          <button>Save</button>
          <button>Forward</button>
        </div>
      </div>

      <div className="table-container border border-[#ECECEC] my-9 ">
        <div className="overflow-x-scroll mb-9 ">
          <table className="twelve-column-table border-collapse w-full text-sm homeTable">
            <thead className="bg-[#F4F4F4]">
              <tr>
                <td>
                  <input type="checkbox" />
                </td>
                <td>SN</td>
                <td>Input</td>
                <td>From</td>
                <td>To</td>
                <td>Airline</td>
                <td>Flight No.</td>
                <td>Class</td>

                <td>
                  Departure
                  <br /> Date/Time
                </td>
                <td>Arrival Date/Time</td>
                <td>Cost</td>
                <td>Currency</td>
              </tr>
            </thead>
            <tbody>
              {ticketReqUsers.map((it, ind) => (
                <tr>
                  <td>
                    <input type="checkbox" />
                  </td>
                  <td>{ind + 1}</td>
                  <td>
                    <div className="border border-[#D9D9D9] flex items-center justify-between px-2 h-[33px] rounded-[3px] outline-none  ">
                      <input
                        type="text"
                        value={it.name}
                        className="outline-none "
                      />
                      <RiSearch2Line className="text-[#D9D9D9] text-[22px]" />
                    </div>
                  </td>
                  <td>
                    <input
                      type="text"
                      className=" border border-[#D9D9D9] items-center justify-between px-2 h-[33px] rounded-[3px] w-[86px]"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className=" border border-[#D9D9D9] items-center justify-between px-2 h-[33px] rounded-[3px] w-[86px]"
                    />
                  </td>
                  <td>
                    {" "}
                    <div className="flex border border-[#D9D9D9] items-center justify-between px-2 h-[33px] rounded-[3px] w-[86px]">
                      <select
                        type="text"
                        className="w-full h-full outline-none bg-white whitespace-nowrap"
                      />
                    </div>
                  </td>
                  <td>
                    <input
                      type="text"
                      className="flex border border-[#D9D9D9] items-center justify-between px-2 h-[33px] rounded-[3px] w-[86px]"
                    />
                  </td>
                  <td>
                    <div className="flex border border-[#D9D9D9] items-center justify-between px-2 h-[33px] rounded-[3px] w-[86px]">
                      <select
                        type="text"
                        className="w-full h-full outline-none bg-white"
                      />
                    </div>
                  </td>
                  <td>
                    <div className="flex items-start">
                      <span className="text-[#1FADFB]">
                        <FiCalendar className="h-[20px] w-[20px] mr-1" />
                      </span>
                      <span className="text-nowrap">
                        2024-02-14 <br />
                        06:00
                      </span>
                    </div>
                  </td>
                  <td>
                    {" "}
                    <div className="flex">
                      <span className="text-[#1FADFB]">
                        <FiCalendar className="h-[20px] w-[20px] mr-1" />
                      </span>
                      <span className="text-nowrap">
                        2024-03-20 <br />
                        06:00
                      </span>
                    </div>
                  </td>
                  <td>
                    {" "}
                    <input
                      type="text"
                      className="flex border border-[#D9D9D9] items-center justify-between px-2 h-[33px] rounded-[3px] w-[86px]"
                    />
                  </td>
                  <td>
                    {" "}
                    <div className="flex border border-[#D9D9D9] items-center justify-between px-2 h-[33px] rounded-[3px] w-[86px]">
                      <select type="text" className="w-full h-full" />
                    </div>
                  </td>
                </tr>
              ))}
              <tr className="h-[60px]">
                <td
                  colSpan={2}
                  className="bg-[#F4F4F4] font-medium text-center"
                >
                  Total
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="flex justify-end gap-2 mb-4 items-center mx-2">
          <p>2 record in total</p>
          <div className="*:w-[35px] *:h-[35px] *:flex flex gap-2 items-center *:justify-center text-[#CFCDCD]">
            <div className=" border border-[#D9D9D9] items-center justify-between px-2 h-[33px] rounded-[3px] gap-[7px]">
              <MdChevronLeft className="text-[22px]" />
            </div>
            <div className=" border border-[#D9D9D9] items-center justify-between px-2 h-[33px] rounded-[3px] ">
              <MdChevronLeft className="text-[22px]" />
            </div>
            <div className=" border text-[#ffff] border-[#D9D9D9] bg-[#1FADFB] items-center justify-between px-2 font-light h-[33px] rounded-[3px] ">
              1
            </div>
            <div className=" border border-[#D9D9D9] items-center justify-between px-2 h-[33px] rounded-[3px] gap-[7px]">
              <MdKeyboardArrowRight className="text-[22px]" />
            </div>
            <div className=" border border-[#D9D9D9] items-center justify-between px-2 h-[33px] rounded-[3px] ">
              <MdKeyboardArrowRight className="text-[22px]" />
            </div>
          </div>
          <div className="border border-[#D9D9D9] flex items-center  px-2 h-[33px] rounded-[3px] w-fit">
            <p className="text-sm font-normal gap-2">10 Items/Page</p>
            <IoIosArrowDown className="text-[#474747] text-[20px]" />
          </div>
          <p className="text-sm font-normal">Go to</p>
          <div className="border border-[#D9D9D9] flex items-center  px-2 h-[33px] rounded-[3px] w-fit">
            <p>1</p>
          </div>
        </div>
      </div>
      <div className="flex justify-end my-5">
        <button
          className="flex bg-[#F5972A] rounded-[3px] whitespace-nowrap w-[190px] h-[40px] p-[21px] items-center text-white text-nowrap "
          onClick={() => setOpenAgentModal(true)}
        >
          Select Travel Agent
        </button>
      </div>
      <SelectModal
        onClose={() => setOpenAgentModal(false)}
        openAgentModal={openAgentModal}
      />
      <div>
        <img src="assets/img/image.png" alt="" />
      </div>
    </div>
  );
}

export default Home;
