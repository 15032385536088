import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from "./App";
import "./index.css";
import Home from "./pages/home";
import Login from "./pages/login";
import Approval from "./pages/approval";
import Receipt from "./pages/receipt";

const router = createBrowserRouter([
  // {
  //   path: "/login",
  //   element: <Login />,
  // },
  {
    path: "/",
    element: <App />,

    children: [
      { path: "", element: <Home /> },
      { path: "login", element: <Login /> },
      { path: "approval", element: <Approval /> },
      { path: "receipt", element: <Receipt /> },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
