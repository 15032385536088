import React from "react";
import { toast } from "react-toastify";
import receiptImg from "../../assets/images/receipt.png";
function Receipt() {
  return (
    <div className="mx-7">
      <div className="flex justify-between">
        <div className="my-2  flex gap-7 font-medium text-sm">
          <span className="text-[#1BABF8] border-b-[3px] border-[#1BABF8]">
            Workflow From
          </span>
          <span>Workflow Chart</span>
          <span>Process status</span>
          <span>Related resources </span>
        </div>
      </div>

      <div className="my-4">
        <div className="flex justify-between items-center mb-4">
          <p className="text-2xl font-medium">Ticket Details</p>
          <button
            className="text-[#FFFFFF] bg-[#25A9F3] flex items-center border border-[#D9D9D9] rounded-[3px] px-[40px] h-[40px]"
            // onClick={() => setOpenConfirmation(true)}
            onClick={() => {
              //   toast.success("The travel plan has been approved successfully");
            }}
          >
            Send To Employee
          </button>
        </div>

        <div className="bg-[#ececec] flex justify-center items-center py-14 px-20">
          <img src={receiptImg} alt="" />
        </div>
      </div>
    </div>
  );
}

export default Receipt;
