import emirateIcon from "../assets/images/airlines/emirate.jpg";
import jazeeraIcon from "../assets/images/airlines/jazeera.png";
import etihad from "../assets/images/airlines/etihad.png";
import jetAir from "../assets/images/airlines/jetAir.jpeg";
import AieAsia from "../assets/images/airlines/AirAsia.png";
import spiceJet from "../assets/images/airlines/spiceJet.jpeg";
import AirArabia from "../assets/images/airlines/air-arabia.png";

import akbarTravels from "../assets/images/agents/akbarTravels.png";
import gulfAir from "../assets/images/agents/gulfAir.png";
import alSahil from "../assets/images/agents/alSahil.png";
import goldenGate from "../assets/images/agents/goldenGate.png";

const profileImages = [
  "https://randomuser.me/api/portraits/med/men/1.jpg",
  "https://randomuser.me/api/portraits/med/men/21.jpg",
  "https://randomuser.me/api/portraits/med/men/45.jpg",
  "https://randomuser.me/api/portraits/med/men/51.jpg",
  "https://randomuser.me/api/portraits/med/men/3.jpg",
  "https://randomuser.me/api/portraits/med/men/39.jpg",
  "https://randomuser.me/api/portraits/med/men/98.jpg",
  "https://randomuser.me/api/portraits/med/men/34.jpg",
  "https://randomuser.me/api/portraits/med/men/70.jpg",
  "https://randomuser.me/api/portraits/med/men/97.jpg",
];

const agents = [
  {
    id: 1,
    name: "Akbar Travels",
    image: akbarTravels,
  },
  {
    id: 2,
    name: "Gulf Air",
    image: gulfAir,
  },
  {
    id: 3,
    name: "Al SAHIL",
    image: alSahil,
  },
  {
    id: 4,
    name: "Golden gate",
    image: goldenGate,
  },
  {
    id: 5,
    name: "Akbar Travels",
    image: akbarTravels,
  },
  {
    id: 6,
    name: "Gulf Air",
    image: gulfAir,
  },
  {
    id: 7,
    name: "Al SAHIL",
    image: alSahil,
  },
  {
    id: 8,
    name: "Golden gate",
    image: goldenGate,
  },
  {
    id: 9,
    name: "Akbar Travels",
    image: akbarTravels,
  },
  {
    id: 10,
    name: "Gulf Air",
    image: gulfAir,
  },
  {
    id: 9,
    name: "Akbar Travels",
    image: akbarTravels,
  },
];

const ticketReqUsers = [
  {
    id: 1,
    name: "Karzan Anwar Salim",
    image: profileImages[1],
    travellers: 1,
    tickets: [
      { from: "Kuwait", to: "Doha", dateAndTime: "2024-02-14, 06:00" },
      { from: "Doha", to: "Kuwait", dateAndTime: "2024-03-14, 11:00" },
    ],
  },
  {
    id: 2,
    name: "Shibin",
    image: profileImages[Math.ceil(Math.random() * 10)],
    travellers: 1,
    tickets: [{ from: "Riyadh", to: "Doha", dateAndTime: "2024-02-14, 06:00" }],
  },
];

const tickets = [
  {
    id: 1,
    airline: "Qatar Airways",
    airlineCode: "QR 1073",
    airlineLogo: emirateIcon,
    from: "Kuwait",
    to: "Doha",
    date: "2024-02-14",
    isBest: true,
    currency: "KWD",
    currentPrice: "1099",
    maxPrice: "1200",
    agent: agents[Math.ceil(Math.random() * 10)],
    times: [
      {
        from: "22:25",
        to: "23:49",
        left: 4,
        duration: { hr: "01", min: "25" },
      },
      {
        from: "01:00",
        to: "06:05",
        left: 9,
        duration: { hr: "01", min: "25" },
      },
    ],
  },
  {
    id: 2,
    airline: "Jazeera Airways",
    airlineCode: "J9 - 117",
    airlineLogo: jazeeraIcon,
    from: "Kuwait",
    to: "Doha",
    date: "2024-02-14",
    isBest: false,
    currency: "KWD",
    currentPrice: "1799",
    maxPrice: "2100",
    agent: agents[3],
    times: [
      {
        from: "00:24",
        to: "01:49",
        left: 4,
        duration: { hr: "01", min: "25" },
      },
    ],
  },
  {
    id: 3,
    airline: "Etihadc",
    airlineCode: "EY 306",
    airlineLogo: etihad,
    from: "Kuwait",
    to: "Doha",
    date: "2024-02-14",
    isBest: false,
    currency: "KWD",
    currentPrice: "1799",
    maxPrice: "3500",
    agent: agents[Math.ceil(Math.random() * 10)],
    times: [
      {
        from: "21:00",
        to: "03:49",
        left: 4,
        duration: { hr: "01", min: "25" },
      },
      {
        from: "01:00",
        to: "06:05",
        left: 9,
        duration: { hr: "01", min: "25" },
      },
    ],
  },
  {
    id: 4,
    airline: "Jet Airways",
    airlineCode: "J9 115",
    airlineLogo: jetAir,
    from: "Kuwait",
    to: "Doha",
    date: "2024-02-14",
    isBest: true,
    currency: "KWD",
    currentPrice: "1799",
    maxPrice: "2100",
    agent: agents[Math.ceil(Math.random() * 10)],
    times: [
      {
        from: "19:45",
        to: "09:49",
        left: 5,
        duration: { hr: 5, min: "25" },
      },
    ],
  },
  {
    id: 5,
    airline: "AirAsia",
    airlineCode: "J9 115",
    airlineLogo: AieAsia,
    from: "Kuwait",
    to: "Doha",
    date: "2024-02-14",
    isBest: false,
    currency: "KWD",
    currentPrice: "1799",
    maxPrice: "4000",
    agent: agents[Math.ceil(Math.random() * 10)],
    times: [
      {
        from: "19:45",
        to: "10:49",
        left: 4,
        duration: { hr: 2, min: "25" },
      },
    ],
  },
  {
    id: 6,
    airline: "SpiceJet",
    airlineCode: "J9345",
    airlineLogo: spiceJet,
    from: "Kuwait",
    to: "Doha",
    date: "2024-02-14",
    isBest: false,
    currency: "KWD",
    currentPrice: "1799",
    maxPrice: "2200",
    agent: agents[Math.ceil(Math.random() * 10)],
    times: [
      {
        from: "19:45",
        to: "09:49",
        left: 4,
        duration: { hr: 2, min: "25" },
      },
    ],
  },
  {
    id: 7,
    airline: "Air arabia",
    airlineCode: "J23115",
    airlineLogo: AirArabia,
    from: "Kuwait",
    to: "Doha",
    date: "2024-02-14",
    isBest: true,
    currency: "KWD",
    currentPrice: "1899",
    maxPrice: "4000",
    agent: agents[Math.ceil(Math.random() * 10)],
    times: [
      {
        from: "10:00",
        to: "01:50",
        left: 6,
        duration: { hr: "01", min: "10" },
      },
    ],
  },
];

export { ticketReqUsers, tickets, agents };
