import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  return (
    <div className="container mx-auto flex items-center justify-center h-[80vh]">
      <div className="shadow-[0px_0px_34px_0px_#96A8B994] p-[70px] rounded-xl">
        <h1 className="font-bold text-2xl mb-3">Login</h1>
        <form className="flex flex-col  ">
          <input
            type="email"
            name="email"
            id="email"
            value={username}
            onChange={(e) => {
              setUsername(e.target.value);
            }}
            placeholder="Enter email"
            className="mb-7 border-[#90999F] border w-[400px] h-[50px] text-normal rounded-[6px] px-4 placeholder:text-[#A5ACB3] placeholder:font-light placeholder:text-sm"
          />
          <input
            type="password"
            name="password"
            id="password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            placeholder="Enter password"
            className="border-[#90999F] border w-[400px] h-[50px] mb-4 rounded-[6px] px-4 placeholder:text-[#A5ACB3] placeholder:font-light placeholder:text-sm "
          />
          <div className="text-[#1BABF8] font-medium text-base flex justify-between mt-[38px]">
            <Link>Forgot Password</Link>
            <button
              type="submit"
              className="text-[#FFFFFF] bg-[#25A9F3] flex items-center  rounded-[3px] px-[40px] h-[40px] mb-[38px] w-[169px] justify-center"
              onClick={() => {
                if (username && password) {
                  navigate("/approval");
                } else {
                  toast.error("Please enter credentials");
                }
              }}
            >
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
