import React, { useState } from "react";
import Modal from "../../components/common/modal";
import { IoClose } from "react-icons/io5";
import { TfiClose } from "react-icons/tfi";
import ConfirmationModal from "./confirmationModal";

const SelectModal = ({ onClose, openAgentModal }) => {
    const [openConfirmation, setOpenConfirmation] = useState(false);

    const agents = [
        "All",
        "Akbar Travels",
        " Al Ostora Travel Company.",
        " Alshamel Travel",
        " Akbar Travels",
        "Captain Tours and Travels Company",
        " Akbar Travels",
        " Alshamel Travel",
        " Captain Tours and Travels Company",
        " Akbar Travels ",
        " Golden Gate",
        "Akbar Travels",
    ];
    if (!openAgentModal) {
        return null;
    }
    if (openConfirmation)
        return (
            <ConfirmationModal
                onClose={() => setOpenConfirmation(false)}
                onSuccess={() => {
                    onClose();
                    setOpenConfirmation(false);
                }}
            />
        );

    return (
        <Modal.Body className="w-[700px]">
            <div className="flex items-start justify-between px-[25px] pt-[25px] rounded-t">
                <h3 className="text-[20px] text-[#013E4F] mb-[24px] font-semibold ">
                    Select Travel Agents
                </h3>
                <TfiClose
                    className="w-[22px] h-[22px] text-[#B7B9BB]"
                    onClick={onClose}
                />
            </div>

            <hr />
            <div className="flex  justify-start flex-col h-[360px] gap-5 flex-wrap px-8 text-sm mt-8 w-fit">
                {agents.map((item, index) => (
                    <div key={index} className="flex gap-2 ">
                        <input
                            type="checkbox"
                            id="checkbox1"
                            className="peer relative h-6 w-6 shrink-0 appearance-none rounded-sm border after:absolute after:left-0 after:top-0 after:h-full after:w-full after:bg-[url('data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjZmZmZmZmIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgdmVyc2lvbj0iMS4xIiB4PSIwcHgiIHk9IjBweCI+PHRpdGxlPmljb25fYnlfUG9zaGx5YWtvdjEwPC90aXRsZT48ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz48ZyBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48ZyBmaWxsPSIjZmZmZmZmIj48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyNi4wMDAwMDAsIDI2LjAwMDAwMCkiPjxwYXRoIGQ9Ik0xNy45OTk5ODc4LDMyLjQgTDEwLjk5OTk4NzgsMjUuNCBDMTAuMjI2Nzg5MSwyNC42MjY4MDE0IDguOTczMTg2NDQsMjQuNjI2ODAxNCA4LjE5OTk4Nzc5LDI1LjQgTDguMTk5OTg3NzksMjUuNCBDNy40MjY3ODkxNCwyNi4xNzMxOTg2IDcuNDI2Nzg5MTQsMjcuNDI2ODAxNCA4LjE5OTk4Nzc5LDI4LjIgTDE2LjU4NTc3NDIsMzYuNTg1Nzg2NCBDMTcuMzY2ODIyOCwzNy4zNjY4MzUgMTguNjMzMTUyOCwzNy4zNjY4MzUgMTkuNDE0MjAxNCwzNi41ODU3ODY0IEw0MC41OTk5ODc4LDE1LjQgQzQxLjM3MzE4NjQsMTQuNjI2ODAxNCA0MS4zNzMxODY0LDEzLjM3MzE5ODYgNDAuNTk5OTg3OCwxMi42IEw0MC41OTk5ODc4LDEyLjYgQzM5LjgyNjc4OTEsMTEuODI2ODAxNCAzOC41NzMxODY0LDExLjgyNjgwMTQgMzcuNzk5OTg3OCwxMi42IEwxNy45OTk5ODc4LDMyLjQgWiI+PC9wYXRoPjwvZz48L2c+PC9nPjwvc3ZnPg==')] after:bg-[length:40px] after:bg-center after:bg-no-repeat after:content-[''] checked:bg-[#25A9F3] hover:ring hover:ring-gray-300 focus:outline-none"
                        />
                        <span>{item}</span>
                    </div>
                ))}
            </div>
            <hr />
            <Modal.Footer>
                <button
                    onClick={onClose}
                    className="text-[#FFFFFF] bg-[#BCBCBC] flex items-center border border-[#D9D9D9] rounded-[3px] px-[24px] h-[40px] gap-1"
                >
                    <IoClose className="h-[22px] w-[22px]" />
                    Cancel
                </button>
                <button
                    className="text-[#FFFFFF] bg-[#25A9F3] flex items-center border border-[#D9D9D9] rounded-[3px] px-[40px] h-[40px]"
                    onClick={() => setOpenConfirmation(true)}
                >
                    Send
                </button>
            </Modal.Footer>
        </Modal.Body>
    );
};

export default SelectModal;
